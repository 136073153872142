import { ValidChannels } from './validChannels'
const keywordChannels = [1, 3, 4, 10, 24]
export const settingsNav = {
  account: {
    settings: {
      label: 'Account Settings & Tools',
      sections: [
        {
          label: 'Budgeting',
          links: [
            {
              label: 'Budget Settings',
              defaultPriority: 2,
              query: {
                form: 'budgeting',
                content: 'settings'
              },
              searchTerms: [
                'Overspend',
                'Next Month',
                'Reallocation'
              ]
            },
            {
              label: 'Groups',
              mustHaveCapabilities: ['CampaignGroupBudgets'],
              defaultPriority: 1,
              query: {
                form: 'budgeting',
                content: 'groups'
              }
            }
          ]
        },
        {
          label: 'Account Tools',
          links: [
            {
              label: 'Conversion Actions',
              validChannels: [29, 30, 32],
              query: {
                form: 'conversionActions'
              }
            },
            {
              label: 'Broadcast',
              validChannels: [1, 2],
              query: {
                form: 'broadcast'
              }
            },
            {
              label: 'Account Performance',
              query: {
                form: 'accountPerformance'
              }
            },
            {
              label: 'Keyword Recommendations',
              validChannels: keywordChannels,
              query: {
                form: 'keywordRecommendations'
              }
            },
            {
              label: 'Blocked Keywords',
              validChannels: keywordChannels,
              query: {
                form: 'blockedKeywords'
              }
            },
            {
              label: 'Phone Routing',
              mustHaveUserCapabilities: ['PhoneRouting'],
              query: {
                form: 'phoneRouting'
              }
            },
            {
              label: 'Display Ads',
              mustHaveCapabilities: ['DisplayAds'],
              query: {
                form: 'displayads'
              }
            }
          ]
        },
        {
          label: 'Partners',
          links: [
            {
              label: 'Partner Accounts',
              query: {
                form: 'partners',
                content: 'partnerAccounts'
              },
              searchTerms: [
                'Customer ID',
                'Overspend Detection',
                'Included Spend Strategy'
              ]
            },
            {
              label: 'Tracking Tags',
              query: {
                form: 'partners',
                content: 'trackingTags'
              }
            },
            {
              label: 'Name Overrides',
              mustHaveCapabilities: ['NamingOverrides'],
              query: {
                form: 'partners',
                content: 'nameOverrides'
              }
            },
            {
              label: 'VLA Checklist',
              mustHaveCapabilities: ['GoogleVehicleAds'],
              query: {
                form: 'vlaChecklist'
              }
            },
            {
              label: 'Pinterest Checklist',
              mustHaveCapabilities: ['Pinterest'],
              query: {
                form: 'pinterestChecklist'
              }
            },
            {
              label: 'Options',
              mustHaveSettingsItemKeys: ['settings'],
              query: {
                form: 'basic',
                content: 'options'
              }
            },
            {
              label: 'YouTube Channel',
              mustHaveSettingsItemKeys: ['youtubeChannelId'],
              query: {
                form: 'youtubeChannel'
              }
            }
          ]
        },
        {
          label: 'Basic',
          links: [
            {
              label: 'Account Details',
              query: {
                form: 'basic',
                content: 'general'
              },
              searchTerms: [
                'Basic Settings',
                'Account Name',
                'Advertised Name',
                'Short Advertised Name',
                'User Settings',
                'Assigned User',
                'Reverse Sync',
                'Pull From Partners',
                'URL Settings',
                'Domain',
                'Website Provider',
                'Identification Settings',
                'UUID',
                'Vertical',
                'Time Zone',
                'Currency',
                'Business Hours of Operation',
                'Contact',
                'Phone',
                'e-mail',
                'Address',
                'State',
                'Zip',
                'City',
                'Country',
                'Street',
                'Apartment',
                'Studio',
                'Floor'
              ]
            }
          ]
        }
      ]
    },
    targeting: {
      label: 'Account Targeting',
      sections: [
        {
          label: 'Cross-Channel',
          links: [
            {
              label: 'Locations',
              defaultPriority: 1,
              query: {
                form: 'locations'
              }
            }
          ]
        },
        {
          label: 'Search',
          validChannels: [1],
          links: [
            {
              label: 'Google Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForSearch'
              },
              searchTerms: [
                'user list',
                'audience'
              ]
            },
            {
              label: 'Negative Keyword Sets',
              validChannels: keywordChannels,
              query: {
                form: 'sharedsets'
              }
            }
          ]
        },
        {
          label: 'Display',
          validChannels: [3],
          links: [
            {
              label: 'Display Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForDisplay'
              },
              searchTerms: [
                'user list',
                'audience'
              ]
            }
          ]
        },
        {
          label: 'DSP',
          validChannels: [29, 30, 32],
          links: [
            {
              label: 'Domain Lists',
              query: {
                form: 'blueprints',
                content: 'domainLists'
              }
            },
            {
              label: 'Contextual Targets',
              query: {
                form: 'targetSets',
                content: 'DSP_CONTEXTUAL_TARGET'
              }
            },
            {
              label: 'Audiences',
              query: {
                form: 'targetSets',
                content: 'DSP_AUDIENCE'
              },
              searchTerms: [
                'user list',
                'audience',
                'target set'
              ]
            },
            {
              label: 'Audience Groups',
              query: {
                form: 'targetSets',
                content: 'DSP_AUDIENCE_GROUP'
              }
            },
            {
              label: 'Custom Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForDsp'
              },
              searchTerms: [
                'user list',
                'audience'
              ]
            }
          ]
        },
        {
          label: 'Social',
          validChannels: [2],
          links: [
            {
              label: 'Meta User Lists',
              query: {
                form: 'userlists',
                content: 'eligibleForFacebook'
              },
              searchTerms: [
                'user list',
                'audience'
              ]
            },
            {
              label: 'Facebook Page Posts',
              query: {
                form: 'pagePosts'
              }
            },
            {
              label: 'Meta Audience Network',
              query: {
                form: 'metaAudience',
                content: 'META_AUDIENCE_NETWORK_PLACEMENT'
              }
            }
          ]
        }
      ]
    },
    blueprints: {
      label: 'Blueprint Data',
      sections: [
        {
          label: 'Data Sources',
          links: [
            {
              label: 'External Data Sources',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'pollingSources'
              }
            },
            {
              label: 'Media Sources',
              defaultPriority: 2,
              query: {
                form: 'blueprints',
                content: 'mediaSources'
              }
            },
            {
              label: 'Account Data',
              defaultPriority: 1,
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'customData'
              }
            },
            {
              label: 'Form Data',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'formData'
              }
            },
            {
              label: 'Promotion Scheduler',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'promotionScheduler'
              }
            },
            {
              label: 'Sheet Data',
              mustHaveUserCapabilities: ['Blueprints', 'GSheetDataSource'],
              query: {
                form: 'blueprints',
                content: 'sheetData'
              }
            }
          ]
        },
        {
          label: 'Blueprint Tools',
          links: [
            {
              label: 'Feature Flags',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'featureFlags'
              }
            },
            {
              label: 'Blueprint Tweaks',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'blueprintTweaks'
              }
            },
            {
              label: 'Subscriptions',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'blueprints',
                content: 'subscriptions'
              }
            },
            {
              label: 'Catalogs',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'productcatalogs'
              }
            },
            {
              label: 'Competitors',
              mustHaveUserCapabilities: ['Blueprints'],
              query: {
                form: 'basic',
                content: 'competitors'
              }
            }
          ]
        }
      ]
    }
  },
  campaign: {
    settings: {
      label: 'Campaign Settings & Tools',
      sections: [
        {
          label: 'General',
          links: [
            {
              label: 'Campaign Details',
              defaultPriority: 1,
              validChannels: ValidChannels.CampaignTabs.basic,
              query: {
                form: 'basic'
              },
              searchTerms: [
                'Name',
                'Status',
                { text: 'Ad Rotation', validChannels: keywordChannels },
                'Tracking Template',
                'Final URL Suffix',
                'Group Name',
                'Dynamic Search',
                'Budget Type',
                'Shared Budget',
                'Recurring Monthly',
                'Manual Hold',
                'Start Date',
                'End Date',
                { text: 'Traffic Type', validChannels: [29, 30, 32] },
                { text: 'Page Position', validChannels: [29, 30] },
                { text: 'Device Type', validChannels: [29, 30, 32] },
                { text: 'Enable Cross Device Tracking', validChannels: [29, 30, 32] },
                { text: 'Viewability', validChannels: [29, 30] },
                { text: 'Frequency Caps', validChannels: [29, 30, 32] },
                { text: 'Special Ad Categories', validChannels: [2] },
                { text: 'Credit', validChannels: [2] },
                { text: 'Housing', validChannels: [2] },
                { text: 'Employment', validChannels: [2] },
                { text: 'Promoted Page Override', validChannels: [2] },
                { text: 'Goal', validChannels: [2] },
                { text: 'URL Parameters', validChannels: [2] }
              ]
            },
            {
              label: 'Bidding',
              validChannels: ValidChannels.CampaignTabs.bidding,
              query: {
                form: 'bidding'
              }
            },
            {
              label: 'Attributes',
              validChannels: ValidChannels.CampaignTabs.attributes,
              query: {
                form: 'attributes'
              }
            },
            {
              label: 'Phone Routing',
              mustHaveUserCapabilities: ['PhoneRouting'],
              validChannels: ValidChannels.CampaignTabs.phoneRouting,
              query: {
                form: 'phoneRouting'
              }
            },
            {
              label: 'Insertion Orders/Line Items',
              validChannels: ValidChannels.CampaignTabs.dv360,
              query: {
                form: 'dv360'
              }
            },
            {
              label: 'Inventory',
              validChannels: ValidChannels.CampaignTabs.inventory,
              query: {
                form: 'inventory'
              }
            },
            {
              label: 'Conversions',
              validChannels: ValidChannels.CampaignTabs.conversions,
              query: {
                form: 'conversions'
              }
            },
            {
              label: 'RTB Configurations',
              validChannels: ValidChannels.CampaignTabs['rtb-configurations'],
              query: {
                form: 'rtb-configurations'
              }
            },
            {
              label: 'Trackers',
              validChannels: ValidChannels.CampaignTabs.trackers,
              query: {
                form: 'trackers'
              }
            }
          ]
        },
        {
          label: 'The Trade Desk',
          validChannels: [29, 30, 32],
          validPartners: [18],
          links: [
            {
              label: 'Settings',
              query: {
                form: 'ttd-settings'
              }
            }
          ]
        },
        {
          label: 'Tools',
          links: [
            {
              label: 'Analyze Campaign',
              query: {
                form: 'analyzeCampaign'
              }
            },
            {
              label: 'Settings Replicator',
              query: {
                form: 'settingsReplicator'
              }
            },
            {
              label: 'Partner Check',
              query: {
                form: 'partnerCheck'
              }
            }
          ]
        }
      ]
    },
    targeting: {
      label: 'Campaign Targeting',
      sections: [
        {
          label: 'Targeting',
          links: [
            {
              label: 'Locations',
              validChannels: ValidChannels.CampaignTabs.locations,
              query: {
                form: 'locations'
              }
            },
            {
              label: 'Targets',
              validChannels: ValidChannels.CampaignTabs.targets,
              query: {
                form: 'targets'
              }
            },
            {
              label: 'Contextual Targets',
              validChannels: ValidChannels.CampaignTabs.targetingAndAudiences,
              query: {
                form: 'targetingAndAudiences',
                content: 'DSP_CONTEXTUAL_TARGET'
              }
            },
            {
              label: 'Audience Groups',
              validChannels: ValidChannels.CampaignTabs.targetingAndAudiences,
              query: {
                form: 'targetingAndAudiences',
                content: 'DSP_AUDIENCE_GROUP'
              }
            },
            {
              label: 'Custom Audiences',
              validChannels: [29, 30],
              query: {
                form: 'userlists'
              }
            },
            {
              label: 'Domain Lists',
              validChannels: ValidChannels.CampaignTabs.domainLists,
              query: {
                form: 'domain-lists'
              }
            },
            {
              label: 'Applied Audiences',
              validChannels: [1, 2, 3, 4, 5, 9, 10],
              query: {
                form: 'userlists'
              }
            },
            {
              label: 'Languages',
              validChannels: ValidChannels.CampaignTabs.languages,
              query: {
                form: 'languages'
              }
            },
            {
              label: 'Network',
              validChannels: ValidChannels.CampaignTabs.network,
              query: {
                form: 'network'
              }
            },
            {
              label: 'Start/End Dates',
              validChannels: ValidChannels.CampaignTabs['start-and-end-dates'],
              query: {
                form: 'start-and-end-dates'
              }
            },
            {
              label: 'Ad Scheduling',
              validChannels: ValidChannels.CampaignTabs['ad-scheduling'],
              query: {
                form: 'ad-scheduling'
              }
            },
            {
              label: 'Services',
              validChannels: ValidChannels.CampaignTabs.services,
              query: {
                form: 'services'
              }
            },
            {
              label: 'Video Config',
              validChannels: ValidChannels.CampaignTabs.videoConfig,
              query: {
                form: 'videoConfig'
              }
            }
          ]
        },
        {
          label: 'Keyword Management',
          validChannels: keywordChannels,
          links: [
            {
              label: 'Negative Keywords',
              query: {
                form: 'negative-keywords'
              }
            },
            {
              label: 'Blocked Keywords',
              validChannels: [1, 3, 4, 10, 24],
              query: {
                form: 'blockedKeywords'
              }
            },
            {
              label: 'Keyword Recommendations',
              validChannels: [1, 3, 4, 10, 24],
              query: {
                form: 'keywordRecommendations'
              }
            }
          ]
        }
      ]
    }
  },
  adgroup: {
    settings: {
      label: 'Ad Group Settings & Targeting',
      sections: [
        {
          label: 'General',
          links: [
            {
              label: 'Ad Group Details',
              validChannels: ValidChannels.AdGroupTabs.basic,
              defaultPriority: 1,
              query: {
                form: 'basic'
              },
              searchTerms: [
                'Name',
                'Status',
                'Bid Type',
                { text: 'Max CPC', validChannels: [keywordChannels] },
                'URL Settings',
                'Default Destination URL',
                'Tracking Template',
                'Final URL Suffix',
                'Ad Rotation',
                { text: 'Budget Weight', validChannels: [2] },
                { text: 'Budget Spend Limits', validChannels: [2] },
                { text: 'When You Get Charged', validChannels: [2] },
                { text: 'URL Parameters', validChannels: [2] },
                { text: 'Conversion Location', validChannels: [2] },
                { text: 'Ad delivery Optimization', validChannels: [2] },
                { text: 'Product Set', validChannels: [2] }
              ]
            },
            {
              label: 'Attributes',
              validChannels: ValidChannels.AdGroupTabs.attributes,
              query: {
                form: 'attributes'
              }
            },
            {
              label: 'Partner Check',
              query: {
                form: 'partnerCheck'
              }
            }
          ]
        },
        {
          label: 'Targeting',
          links: [
            {
              label: 'Negative Keywords',
              validChannels: ValidChannels.AdGroupTabs.negativeKeywords,
              query: {
                form: 'negativeKeywords'
              }
            },
            {
              label: 'Start/End Dates',
              validChannels: ValidChannels.AdGroupTabs.startAndEndDates,
              query: {
                form: 'startAndEndDates'
              }
            },
            {
              label: 'Placements',
              validChannels: ValidChannels.AdGroupTabs.placement,
              query: {
                form: 'placement'
              }
            },
            {
              label: 'Audiences',
              validChannels: ValidChannels.AdGroupTabs.userlists,
              query: {
                form: 'audiences'
              }
            },
            {
              label: 'Audience',
              validChannels: ValidChannels.AdGroupTabs.audience,
              query: {
                form: 'audience'
              }
            },
            {
              label: 'Targets',
              validChannels: ValidChannels.AdGroupTabs.targets,
              query: {
                form: 'targets'
              }
            },
            {
              label: 'Locations',
              validChannels: ValidChannels.AdGroupTabs.locations,
              query: {
                form: 'locations'
              }
            },
            {
              label: 'Product Groups',
              validChannels: ValidChannels.AdGroupTabs.productGroups,
              query: {
                form: 'productGroups'
              }
            },
            {
              label: 'Contextual Targets',
              validChannels: ValidChannels.AdGroupTabs.targetingAndAudiences,
              query: {
                form: 'targetSets',
                content: 'DSP_CONTEXTUAL_TARGET'
              }
            },
            {
              label: 'Audience Groups',
              validChannels: ValidChannels.AdGroupTabs.targetingAndAudiences,
              query: {
                form: 'targetSets',
                content: 'DSP_AUDIENCE_GROUP'
              }
            }
          ]
        },
        {
          label: 'The Trade Desk',
          validChannels: [29, 30, 32],
          validPartners: [18],
          links: [
            {
              label: 'Settings',
              query: {
                form: 'ttd-settings'
              }
            }
          ]
        }
      ]
    }
  }
}

export const defaultParams = {
  accountBlueprint: { form: 'blueprints', content: 'pollingSources' },
  accountTarget: { form: 'locations' },
  campaignTarget: { form: 'locations' }
}
